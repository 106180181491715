<template>
  <div class="passengerMonitorInfo">
    <div class="exit" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="info">
      <header>监测详情</header>
      <div class="inner">
        <div class="item">
          <div class="label">线路名称</div>
          <div class="value">{{ info.lineName }}</div>
        </div>
        <div class="item">
          <div class="label">发车日期</div>
          <div class="value">{{ info.departureDate }}</div>
        </div>
        <div class="item">
          <div class="label">购票乘客数</div>
          <div class="value">{{ info.ticketPassengerCount }}</div>
        </div>
        <div class="item">
          <div class="label">发车时间</div>
          <div class="value">{{ info.departureTime }}</div>
        </div>
        <div class="item">
          <div class="label">实际乘客数</div>
          <div class="value">
            {{ info.actualityPassengerCount }}
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <header>监测情况</header>
      <div class="content">
        <div v-for="(item, index) in list" :key="index" class="item">
          <div v-if="list.length > 2" class="station">
            <p class="name">{{ item.stationName }}</p>
            <p v-if="index != list.length - 1">
              购票乘客数：{{ item.ticketPassengerCount || 0 }}人
            </p>
            <p v-if="index != list.length - 1">
              监测人数：{{ item.actualityPassengerCount || 0 }}人
            </p>
          </div>
          <div v-if="item.passengerDetectionAreaRecordVOS" class="records">
            <div
              v-for="(e, seq) in item.passengerDetectionAreaRecordVOS"
              :key="seq"
              class="detial"
            >
              <div class="left">
                <div class="dot">o</div>
                <div class="line"></div>
              </div>
              <div class="middle">
                <div class="time">{{ e.compareTime }}</div>
                <div class="number">
                  监测人数：{{ e.actualityPassengerCount }}人
                </div>
                <div class="number">{{ e.name }}</div>
              </div>
              <div v-if="e.snapPhoto" class="right">
                <div
                  class="monitor"
                  @click="clickImage(url + e.snapPhoto, e.compareResultData)"
                >
                  <img :src="url + e.snapPhoto" alt="" />
                  <canvas class="myCanvas"></canvas>
                </div>
                <img
                  :src="url + e.snapPhoto"
                  alt=""
                  @click="clickImage(url + e.snapPhoto)"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="detial">
              <div class="left">
                <div class="dot">o</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="screen">
      <i class="el-icon-close" @click="show = false"></i>
      <div class="bg">
        <img :src="imgUrl" alt="" />
        <canvas class="bigImage"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import { queryPassengerDetectionRecordInfo } from "@/api/lib/api.js";
import host from "@/api/static/host.js";
export default {
  data() {
    return {
      info: {},
      url: null,
      imgUrl: "",
      show: false,
      list: [],
      count: 0,
    };
  },
  methods: {
    returnList() {
      this.$router.go(-1);
    },
    renderData() {
      const id = this.$route.query.id;
      queryPassengerDetectionRecordInfo({ id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.info = res.data;
          this.list = res.data.lineStationVOS.map((item) => {
            if (item.passengerDetectionAreaRecordVOS) {
              item.ticketPassengerCount =
                item.passengerDetectionAreaRecordVOS[0].ticketPassengerCount;
              const arr = item.passengerDetectionAreaRecordVOS.map(
                (e) => e.actualityPassengerCount
              );
              item.actualityPassengerCount = Math.max(...arr);
            }
            return item;
          });
          res.data.lineStationVOS.forEach((e) => {
            if (e.passengerDetectionAreaRecordVOS)
              this.draw(e.passengerDetectionAreaRecordVOS);
          });
        }
      });
    },
    draw(arr) {
      arr.forEach((e, index) => {
        const obj = JSON.parse(e.compareResultData);
        if (obj) {
          this.$nextTick(() => {
            const canvas = document.querySelectorAll(".myCanvas")[this.count];
            const ctx = canvas.getContext("2d");
            this.count++;
            ctx.lineWidth = 2;
            ctx.strokeStyle = "#FF4500";
            const wr = 300 / obj.data.width;
            const hr = 150 / obj.data.height;
            obj.data.elements.forEach((k) => {
              const b = k.boxes;
              const width = b[2] - b[0];
              const height = b[3] - b[1];
              ctx.strokeRect(b[0] * wr, b[1] * hr, width * wr, height * hr);
            });
          });
        }
      });
    },
    clickImage(url, data) {
      this.imgUrl = url;
      this.show = true;
      if (data) {
        const obj = JSON.parse(data);
        this.$nextTick(() => {
          const canvas = document.querySelector(".bigImage");
          const bg = document.querySelector(".bg");
          const ctx = canvas.getContext("2d");
          const rate = obj.data.width / obj.data.height;
          const rate2 = 500 / obj.data.height;
          canvas.style.width = 500 * rate + "px";
          canvas.style.height = 500 + "px";
          bg.style.width = 500 * rate + "px";
          bg.style.height = 500 + "px";
          canvas.width = 500 * rate;
          canvas.height = 500;
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#FF4500";
          obj.data.elements.forEach((k) => {
            const b = k.boxes;
            const width = b[2] - b[0];
            const height = b[3] - b[1];
            ctx.strokeRect(
              b[0] * rate2,
              b[1] * rate2,
              width * rate2,
              height * rate2
            );
          });
        });
      } else {
        this.$nextTick(() => {
          const img = document.querySelector(".bg>img");
          img.style.height = 500 + "px";
        });
      }
    },
  },
  mounted() {
    this.url = host.imgURL;
    this.renderData();
  },
};
</script>
<style lang="scss" scoped>
.passengerMonitorInfo {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .exit {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);

    i {
      cursor: pointer;
    }
  }
  header {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    flex-shrink: 0;
  }
  .info {
    margin: 16px 10px;
    flex-shrink: 0;
    .inner {
      width: 800px;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 400px;
        display: flex;
        margin-bottom: 20px;
        .label {
          width: 150px;
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
        }
        .value {
          width: 250px;
          color: rgba(0, 0, 0, 0.9);
          flex: 1;
        }
      }
    }
  }
  .list {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    .content {
      overflow: auto;
      flex: 1;
      .item {
        display: flex;
        .station {
          width: 150px;
          text-align: right;
          font-size: 14px;
          margin-right: 16px;
          p {
            margin-bottom: 10px;
            line-height: 28px;
          }
          .name {
            font-weight: bold;
          }
        }
        .detial {
          height: 100px;
          display: flex;
          margin-bottom: 10px;

          .left {
            width: 8px;
            height: 100px;
            .dot {
              height: 24px;
              line-height: 24px;
              text-align: center;
              color: #0052d9;
              font-weight: bold;
              font-size: 12px;
            }
            .line {
              width: 2px;
              margin-left: 3px;
              height: 76px;
              background-color: #0052d9;
            }
          }
          .middle {
            width: 300px;
            margin: 0 20px;
            .time {
              height: 28px;
              line-height: 28px;
              font-size: 16px;
            }
            .number {
              color: rgba(0, 0, 0, 0.6);
              font-size: 14px;
              margin-top: 10px;
            }
          }
          .right {
            display: flex;
            height: 100px;
            .monitor {
              height: 100%;
              position: relative;
              cursor: pointer;
              margin-right: 20px;
              .myCanvas {
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
              }
            }
            img {
              cursor: pointer;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000006c;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #fff;
      font-size: 40px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .bg {
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      canvas {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
