var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "passengerMonitorInfo" }, [
    _c("div", { staticClass: "exit", on: { click: _vm.returnList } }, [
      _c("i", { staticClass: "iconfont its_ba_fanhui" }),
    ]),
    _c("div", { staticClass: "info" }, [
      _c("header", [_vm._v("监测详情")]),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("线路名称")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.lineName)),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("发车日期")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.departureDate)),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("购票乘客数")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.ticketPassengerCount)),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("发车时间")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.departureTime)),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("实际乘客数")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(" " + _vm._s(_vm.info.actualityPassengerCount) + " "),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "list" }, [
      _c("header", [_vm._v("监测情况")]),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _vm.list.length > 2
              ? _c("div", { staticClass: "station" }, [
                  _c("p", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.stationName)),
                  ]),
                  index != _vm.list.length - 1
                    ? _c("p", [
                        _vm._v(
                          " 购票乘客数：" +
                            _vm._s(item.ticketPassengerCount || 0) +
                            "人 "
                        ),
                      ])
                    : _vm._e(),
                  index != _vm.list.length - 1
                    ? _c("p", [
                        _vm._v(
                          " 监测人数：" +
                            _vm._s(item.actualityPassengerCount || 0) +
                            "人 "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            item.passengerDetectionAreaRecordVOS
              ? _c(
                  "div",
                  { staticClass: "records" },
                  _vm._l(
                    item.passengerDetectionAreaRecordVOS,
                    function (e, seq) {
                      return _c("div", { key: seq, staticClass: "detial" }, [
                        _vm._m(0, true),
                        _c("div", { staticClass: "middle" }, [
                          _c("div", { staticClass: "time" }, [
                            _vm._v(_vm._s(e.compareTime)),
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(
                              " 监测人数：" +
                                _vm._s(e.actualityPassengerCount) +
                                "人 "
                            ),
                          ]),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(e.name)),
                          ]),
                        ]),
                        e.snapPhoto
                          ? _c("div", { staticClass: "right" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "monitor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickImage(
                                        _vm.url + e.snapPhoto,
                                        e.compareResultData
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.url + e.snapPhoto,
                                      alt: "",
                                    },
                                  }),
                                  _c("canvas", { staticClass: "myCanvas" }),
                                ]
                              ),
                              _c("img", {
                                attrs: { src: _vm.url + e.snapPhoto, alt: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickImage(_vm.url + e.snapPhoto)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ])
                    }
                  ),
                  0
                )
              : _c("div", [_vm._m(1, true)]),
          ])
        }),
        0
      ),
    ]),
    _vm.show
      ? _c("div", { staticClass: "screen" }, [
          _c("i", {
            staticClass: "el-icon-close",
            on: {
              click: function ($event) {
                _vm.show = false
              },
            },
          }),
          _c("div", { staticClass: "bg" }, [
            _c("img", { attrs: { src: _vm.imgUrl, alt: "" } }),
            _c("canvas", { staticClass: "bigImage" }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "dot" }, [_vm._v("o")]),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "detial" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "dot" }, [_vm._v("o")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }